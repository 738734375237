import { Hint } from '@smart-kasa/ui';

import { IShop } from 'services/api/core/shop/types';
import styles from './Report.module.scss';

interface IReportShop {
  shop: IShop;
}

export const ReportShop = ({ shop }: IReportShop) => (
  <div className={styles.shop}>
    <Hint margin={0}>{shop.taxpayerName}</Hint>
    <Hint margin={0}>{shop.title}</Hint>
    {shop.address && <Hint margin={0}>{shop.address.content}</Hint>}
  </div>
);
