import { Page } from '@smart-kasa/ui';

import { BitrixForm } from 'components';

export const Repair = () => (
  <Page title="Створення заявки на ремонт">
    <BitrixForm
      id="inline/560/0n4uqa"
      src="https://cdn.bitrix24.eu/b25535707/crm/form/loader_560.js"
    />
  </Page>
);
