import { Fragment, ReactNode } from 'react';
import { Hint, Icon } from '@smart-kasa/ui';

import { TransactionType } from 'services/api/core/receipts/enums';

import styles from './ReceiptPaymentMethod.module.scss';

const PAYMENT_METHOD_MAPPING: Record<TransactionType, { name: string; icon: ReactNode }> = {
  [TransactionType.cash]: {
    name: 'Готівка',
    icon: <Icon name="cash" color="accent" size="medium" />,
  },
  [TransactionType.card]: {
    name: 'Банківська картка',
    icon: <Icon name="terminal" color="secondary" size="medium" />,
  },
  [TransactionType.acquiring]: {
    name: 'Еквайринг',
    icon: <Icon name="receipt" color="primary" size="medium" />,
  },
  [TransactionType.accountTransfer]: {
    name: 'За реквізитами (IBAN)',
    icon: <Icon name="bank2" color="primary" size="medium" />,
  },
  [TransactionType.cardTransfer]: {
    name: 'Переказ з картки',
    icon: <Icon name="creditCard" color="secondary" size="medium" />,
  },
};

export type ReceiptPaymentMethodProps = {
  type: TransactionType;
  name?: ReactNode;
  children?: never;
};

export const ReceiptPaymentMethod = ({ type, name }: ReceiptPaymentMethodProps) => (
  <div className={styles.paymentForm}>
    {type === TransactionType.cash ? (
      <div className={styles.paymentMethod}>
        <div className={styles.paymentType}>{PAYMENT_METHOD_MAPPING[type].name}</div>
        {PAYMENT_METHOD_MAPPING[type].icon}
      </div>
    ) : (
      <Fragment>
        <div className={styles.paymentType}>Безготівкова:</div>
        <div className={styles.paymentMethod}>
          <Hint margin={0}>{name || PAYMENT_METHOD_MAPPING[type].name}</Hint>
          {PAYMENT_METHOD_MAPPING[type].icon}
        </div>
      </Fragment>
    )}
  </div>
);
