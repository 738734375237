import { useContext } from 'react';
import { Preloader } from '@smart-kasa/ui';

import { AdminContext } from 'contexts/AdminContext';

import styles from './HelpDesk.module.scss';

const { REACT_APP_KWIZBOT_HOST, REACT_APP_KWIZBOT_LOGIN_KEY } = process.env;

export const HelpDesk = () => {
  const { profile } = useContext(AdminContext);

  if (!profile) return <Preloader />;

  return (
    <iframe
      src={`${REACT_APP_KWIZBOT_HOST}/crmwidget?uk&user=${profile.email}&login_key=${REACT_APP_KWIZBOT_LOGIN_KEY}&redirect_module=OperatorPanelChats&hide_nav=1`}
      id="frame"
      name="Kwizbot"
      title="Kwizbot"
      className={styles.helpDesk}
    ></iframe>
  );
};
