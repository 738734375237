import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { Reducer } from 'redux';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { authApi } from 'services/api/auth/api';
import { coreApi } from 'services/api/core/api';
import { adminApi } from 'services/api/admin/api';
import { awsApi } from 'services/api/aws/api';
import { assetsApi } from 'services/api/core/assets/api';
import { bitrixApi } from 'services/api/bitrix/api';
import { authSlice } from 'features/auth/slice';

import { RESET_STATE_ACTION_TYPE } from './actions/resetState';
import { unauthenticatedMiddleware } from './middlewares/unauthenticatedMiddleware';

const reducers = {
  [authSlice.name]: authSlice.reducer,
  [coreApi.reducerPath]: coreApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [adminApi.reducerPath]: adminApi.reducer,
  [assetsApi.reducerPath]: assetsApi.reducer,
  [awsApi.reducerPath]: awsApi.reducer,
  [bitrixApi.reducerPath]: bitrixApi.reducer,
};

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [authSlice.name],
};

const combinedReducer = combineReducers<typeof reducers>(reducers);

export const rootReducer: Reducer<RootState> = (state, action) => {
  if (action.type === RESET_STATE_ACTION_TYPE) {
    state = {} as RootState;
  }

  return combinedReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat([
      unauthenticatedMiddleware,
      coreApi.middleware,
      authApi.middleware,
      adminApi.middleware,
      assetsApi.middleware,
      awsApi.middleware,
      bitrixApi.middleware,
    ]),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof combinedReducer>;
