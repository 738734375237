import { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { FormattedDate, useIntl } from 'react-intl';
import {
  Avatar,
  AvatarItem,
  Card,
  CardTitle,
  DataTable,
  Hint,
  IDataTableColumn,
  Tag,
} from '@smart-kasa/ui';

import { IShop } from 'services/api/core/shop/types';
import { useGetShopsQuery } from 'services/api/core/shop/api';
import { ShopState } from 'services/api/core/shop/enums';

export const ShopList = () => {
  const { id } = useParams();
  const { formatMessage } = useIntl();
  const { data: shops, isLoading } = useGetShopsQuery({
    ownerId: id,
    skipPagination: true,
  });

  const columns: IDataTableColumn<IShop>[] = [
    {
      key: 'id',
      title: formatMessage({ id: 'rsn.shop.id' }),
      render: (id) => <Hint margin={0}>#{id}</Hint>,
    },
    {
      key: 'title',
      title: formatMessage({ id: 'rsn.shop.title' }),
      width: '37%',
      render: (title, { picture, address }) => (
        <AvatarItem
          avatar={<Avatar src={picture?.url} name={title} />}
          primaryText={title}
          secondaryText={address?.content}
        />
      ),
    },
    {
      key: 'taxpayerName',
      title: formatMessage({ id: 'rsn.shop.organization' }),
      render: (taxpayerName, { taxpayerNumber }) => (
        <Fragment>
          {taxpayerName}
          <Hint margin={0}>
            {formatMessage({ id: 'rsn.shop.tax-id' })}: {taxpayerNumber}
          </Hint>
        </Fragment>
      ),
    },
    {
      key: 'state',
      title: formatMessage({ id: 'rsn.shop.state' }),
      render: (state: number) => (
        <Tag color={state ? 'green' : 'orange'}>
          {formatMessage({ id: `rsn.shop.${ShopState[state]}` })}
        </Tag>
      ),
    },
    {
      key: 'employeesCount',
      title: formatMessage({ id: 'rsn.shop.stats' }),
      render: (employeesCount, { terminalsCount }) => (
        <Fragment>
          <Hint margin={0}>
            {formatMessage({ id: 'rsn.shop.employees' })}: {employeesCount}
          </Hint>
          <Hint margin={0}>
            {formatMessage({ id: 'rsn.shop.terminals' })}: {terminalsCount}
          </Hint>
        </Fragment>
      ),
    },
    {
      key: 'createdAt',
      title: formatMessage({ id: 'rsn.shop.createdAt' }),
      render: (createdAt) => <FormattedDate value={createdAt} />,
    },
  ];

  return (
    <Card>
      <CardTitle>Торгові точки</CardTitle>

      <DataTable
        rowIdKey="id"
        data={shops?.data}
        columns={columns}
        isLoading={isLoading}
        emptyText="Торгові точки відсутні"
      />
    </Card>
  );
};
