import { useEffect } from 'react';

interface IBitrixForm {
  id: string;
  src: string;
}

export const BitrixForm = ({ id, src }: IBitrixForm) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = src + '?' + ((Date.now() / 180000) | 0);
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
    // eslint-disable-next-line
  }, []);

  return <script data-b24-form={id} data-skip-moving="true"></script>;
};
